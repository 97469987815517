.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
.ptg{
  width: 70%;
  background: white;
  min-width: 600px;
  margin-top: 9rem;
  padding-bottom: 10rem !important;
  padding-top: 3rem;
  padding: 3rem;
}

.custom-pagination-div{
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
  height: 3rem;
}

.custom-pagination-div .active .page-link{
  background-color: #6c7ac9;
  color: white;
}

.select-search-options li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #e9e9e9;
}


.select-search-options li button {
  width: 100%;
  height: 100%;
}

span.active-page {
  background-color: #dfe2e4;
  border: none;
}

/* events training */

.rounded-s {
  border-radius: 0.25rem; /* 4px */
}

button.disabled {
  border: none;
  background-color: grey;
  color: #c6b8b8;
  cursor: not-allowed;
}

button.disabled:hover{
  border: none;
  background-color: grey;
  color: #c6b8b8;
  cursor: not-allowed;
}

h2.text-mainBlack-100.text-lg {
  font-size: 28px;
  line-height: 50px;
  font-weight: 600;
}

/* events training */
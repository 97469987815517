@tailwind base;

@tailwind components;

@tailwind utilities;

.mgt5 {
  margin-bottom: 0.4rem;
}
.mgt5 {
  margin-top: 0.5rem;
}
@media (min-width: 1300px) {
  .mv45 {
    position: relative;
    bottom: 5.7rem;
  }
}
.business_details {
  margin-top: 6rem;
}
.cardbody {
  border: 1px solid #d7dee3;
  background: white;
  padding: 25px;
  border-radius: 0.25rem;
  margin-top: 2rem;
}
.p40 {
  padding: 40px !important;
}
.btn-gray {
  background: #979baa !important;
  color: #fff !important;
}

.btn-cta {
  min-width: auto !important;
  font-size: 11px !important;
}
.type-uppercase {
  text-transform: uppercase;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.card .listing-address svg {
  color: rgba(59, 130, 246, 0.5);
  width: 0.75em;
  height: 0.75em;
  line-height: inherit;
}
.color-lime {
  color: rgba(59, 130, 246, 0.5) !important;
}
.lsa {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.listing-address span {
  display: flex;
  margin-right: 1rem;
}
.confirmbn {
  padding: 0.7rem 1.4rem;
  border-radius: 21px;
  margin-top: 2rem !important;
}
.listing-address span {
  margin-bottom: 1rem;
}
.hh6 {
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  color: #616368;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
}
.d-inline i {
  padding-right: 0.4rem;
}
.type-bold {
  font-weight: 600;
}
.abbody {
  padding-bottom: 2rem;
}
.fzx {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.imgcont2 {
  max-width: 233px;
  max-height: 144px;
}

.card1.hours {
  border: 0px solid #d7dee3 !important;
  margin-top: 1rem;
}
.card1 {
  border-radius: 5px;
}
.card1 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.dfjc {
  display: flex;
  justify-content: space-between;
}
.dfjc div,
.dfjc dd,
.dfjc dt {
  width: 157px;
  text-align: left;
  margin-top: 1.3rem;
}
.bg-secondary-1 {
  background: #fff !important;
  padding: 0.4rem;
  padding: 1rem 2rem;
}
.card1.hours .card-header {
  background: rgba(59, 130, 246, 0.5);
  color: #fff;
  padding: 1rem 2rem;
  padding-top: 0.1rem;
}
.text_success {
  color: rgba(59, 130, 246, 0.5);
  font-size: 12px;
}
.text_danger {
  color: rgb(99, 30, 30);
  font-size: 12px;
}
.thumbs-up {
  width: 10px;
}
.flexjct {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
}

.jct2 {
  margin-bottom: -1rem !important;
  margin-top: 2rem;
}

.whatdoudo {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 1rem;
  letter-spacing: normal;
}
.skills {
  background: #3965ff !important;
  border-radius: 40px;
  color: #fff;
  padding: 0.4rem 1rem;
  margin-left: 0.6rem;
}

.plusnew {
  font-size: 19px;
  font-weight: 600;
  color: #3965ff;
  padding-left: 2rem;
  display: flex;
  line-height: 1;
  cursor: pointer;
}

.plusnew1 {
  font-size: 12px;
  background: white;
  display: inline-block;
  font-weight: 500;
  position: relative;
  top: 0.75rem;
  left: 0.7rem;
  color: #001833;
  padding: 0.2rem;
  cursor: pointer;
}
.qflex {
  display: flex;
  margin-top: 2.3rem;
}
.ll122 {
  /* position: absolute; */
  display: flex;
  margin-left: 5rem;
  height: 33px;
  align-items: center;
  top: 2px;
  flex-wrap: wrap;
}
.dlete {
  margin-left: 0.4rem;
  margin-top: 0.9rem;
}
.lass {
  padding-left: 1rem;
}
.img_logo {
  width: 120px;
}
.fflnd {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.full_url22 {
  max-width: 400px;
}
.full_url23 {
  max-width: 300px;
  margin-right: 1.4rem;
}
.full_url2 {
  width: 300px;
}

.slide21 {
  width: 400px;
  margin: auto;
}
.left213 {
  padding-left: 3rem;
  padding-top: 2rm;
}
.skyblue2 {
  background: skyblue;
  color: white;
  padding: 0.4rem 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.skyblue22 {
  background: rgb(147, 184, 142);
  color: white;
  padding: 0.4rem 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}
.image-item__btn-wrapper {
  margin-bottom: 1rem;
}
.right-text {
  text-align: right;
}
.imglistcontainer {
  display: flex;
  max-width: 700px;
  overflow: scroll;
}
.customeselct {
  padding-top: 1rem;
}
.dangersign {
  color: rgb(196, 159, 159);
  font-weight: 800;
  position: relative;
  left: 2rem;
  height: 40px;
  font-size: 30px;
  cursor: pointer;
}
.look45_1 {
  margin-top: 57rem !important;
  min-height: fit-content !important;
}
.look45_1a {
  margin-top: 27rem !important;
}
.categories12 {
  background: #6c7ac9 !important;
  color: white !important;
  font-weight: 100;
  font-size: 10px;
  cursor: pointer;
  margin-right: 0.4rem;
  padding: 0.2rem 0.4rem;
}
.alignleft {
  margin-top: 0.4rem;
  text-align: right;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.tt23 {
  position: absolute;
}

.shift35 {
  padding-left: 1rem;
}

.mglft .css-1s2u09g-control {
  margin-left: 0.4rem;
}

.mglft .css-1s2u09g-control {
  border: none;
}

.lsitb {
  color: rgb(155, 199, 174) !important;
  font-size: 12px;
  padding-top: 1rem;
  padding-left: 1rem;
}

.vb_pp {
  margin-top: 80rem !important;
}

.vb_pp1 {
  margin-top: 10rem;
}

.busness1 {
  margin-bottom: 1rem;
  font-weight: 900;
}

.look45_1axe {
  margin-top: 80rem !important;
}

.backtb {
  color: #3965ff !important;
  border: 1px solid #3965ff !important;
  font-size: 14px;
  padding: 0.5rem 1.8rem !important;
  border-radius: 6px;
}
 .edkit{
  width: 100%;
  align-items: end;
}
.edkit a{
  color: #3965ff;
  position: relative;
  top: 1rem;
  text-align: right;
}
.vb_pp1 {
  margin-top: -30rem;
}
.max-w-3xl {
  max-width: 800px !important;
  width: 761px !important;
}
.look45_1admin{
  max-width: 700px !important;
  width: 700px !important;
  margin-top: 0rem !important;
}
.look4y4{
  top: 2rem !important;
}
.contact13{
  margin-top: .4rem;
}

.editcontact{
  padding: 1rem;
  padding-bottom: 0px;
}
.editcontact div{
  padding-left: 1rem;
  font-weight: 700;
  color: #616368;
} 
.pr4{
  padding-right: 2rem;
}
.poing{
  cursor: pointer !important;
}

.positt1{
  z-index: 200;
  position: absolute;
}
.btnback12{
  position: relative;
  bottom: 4rem;
}
.flexjct{
  background: rgba(59, 130, 246, 0.5);
  color: white;
}
.flexjct span{
  color: white !important;
  padding: 1rem;
}